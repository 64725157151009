import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { useEffect } from "react";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PageLoader from "../components/PageLoader";

const BulkOrders = (props) => {
  const [orders, setOrders] = useState([]);

  const [order, setOrder] = useState([]);
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(true);

  const [filter, setFilter] = useState("All");

  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [order_status, setOrder_status] = useState("");
  const [url, setUrl] = useState("/api/v1/order/admin/read-bulk");

  const [orderContent, setOrderContent] = useState({});

  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    getOrders();
  }, [page, sort, filter, limit]);

  const getOrders = async (search) => {
    var link = "";

    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
      // "&sort=" +
      // sort +
      // "&status=" +
      // filter;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Bulk Orders</h3>
                  </div>
                </div>

                {loader ? (
                  <PageLoader />
                ) : (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="card shadow-sm">
                        <div className="card-inner">
                          <div className="card-filter px-lg-4 ">
                            <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-center"></div>
                          </div>

                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">UUID</th>
                                    <th scope="col">Telegram ID</th>
                                    <th scope="col">Orders</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orders.map((order, index) => (
                                    <tr key={order.uuid}>
                                      <td>{order.uuid}</td>
                                      <td>{order.user?.tgId}</td>
                                      <td>{order.orders.length}</td>
                                      <td>
                                        {new Date(
                                          order.createdAt
                                        ).toLocaleDateString()}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-sm btn-dark"
                                          data-toggle="modal"
                                          data-target="#view"
                                          onClick={() => {
                                            setOrder(order.orders);
                                          }}
                                        >
                                          <em className="icon ni ni-eye"></em>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex justify-content-end mt-5">
                              <nav>
                                <ul className="pagination">
                                  <li
                                    className={
                                      page === 1
                                        ? "page-item disabled"
                                        : "page-item"
                                    }
                                  >
                                    <Link
                                      className="page-link"
                                      to=""
                                      onClick={(e) => {
                                        setPage(page - 1);
                                      }}
                                    >
                                      Prev
                                    </Link>
                                  </li>

                                  <li
                                    className="page-item active"
                                    aria-current="page"
                                  >
                                    <Link className="page-link" to="">
                                      {page}{" "}
                                      <span className="sr-only">(current)</span>
                                    </Link>
                                  </li>

                                  <li
                                    className={
                                      page === totalPages
                                        ? "page-item disabled"
                                        : "page-item"
                                    }
                                  >
                                    <Link
                                      className="page-link"
                                      to=""
                                      tabIndex={-1}
                                      aria-disabled={
                                        page === totalPages ? true : false
                                      }
                                      onClick={(e) => {
                                        setPage(page + 1);
                                      }}
                                    >
                                      Next
                                    </Link>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="view"
        className="modal fade "
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="false"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Bulk Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive" style={{ maxHeight: "400px" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Tracking Id</th>
                      <th scope="col">Total</th>
                      <th scope="col">Created At</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {order?.map((order, index) => (
                      <tr key={order?._id}>
                        <td>{order?.uuid}</td>
                        <td>{order?.fromName}</td>
                        <td>{order?.toName}</td>
                        <td>{order?.tracking}</td>
                        <td>${order?.price}</td>
                        <td>{new Date(order.createdAt).toString()}</td>

                        {/* <td>
                          <button
                            className="btn btn-sm btn-dark"
                            data-toggle="modal"
                            data-target="#view"
                            onClick={() => {
                              // setOrder(order.order);
                            }}
                          >
                            <em className="icon ni ni-eye"></em>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">From</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.FromName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.FromCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.FromStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.FromStreet2} <br />
                        <span className="fw-bold">City:</span> {order.FromCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span>{" "}
                        {order.FromState} <br />
                        <span className="fw-bold">Zip:</span> {order.FromZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.FromCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">To</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.ToName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.ToCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.ToStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.ToStreet2} <br />
                        <span className="fw-bold">City:</span> {order.ToCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span> {order.ToState}{" "}
                        <br />
                        <span className="fw-bold">Zip:</span> {order.ToZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.ToCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">
                        Additional details
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Label Type:</span>{" "}
                        {order.labelType?.name} <br />
                        <span className="fw-bold">Weight:</span> {order.Weight}
                        {order.labelType?.name.includes("First Class") ? (
                          <span className="fw-bold">oz</span>
                        ) : (
                          <span className="fw-bold">lb</span>
                        )}{" "}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="d-flex justify-content-end">
                <button className="btn btn-dark me-2" title="Download">
                  <i className="bi-download"></i>
                </button>
                <button className="btn btn-dark" title="Refund">
                  <i className="bi-currency-exchange"></i>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOrders;
