import React, { useState, useEffect, Fragment } from "react";
import Loader from "../components/Loader";
import Header from "../components/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike", "image", "code"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: ["#fff"] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const Tos = (props) => {
  const [tos, setTos] = useState("");
  const [loader, setLoader] = useState("");

  useEffect(() => {
    readTos();
  }, []);

  const readTos = async () => {
    await axios
      .get(server + "/api/v1/tos/read")
      .then((res) => {
        setTos(res.data.tos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTOS = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      tos,
    };

    await axios
      .post(server + "/api/v1/tos/update", params, config)
      .then((res) => {
        toast.success(res.data.message);
        setLoader("");
        readTos();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Terms of Service</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={updateTOS}>
                      <div className="form-group d-flex justify-content-center ">
                        <ReactQuill
                          modules={{ toolbar: toolbarOptions }}
                          theme="snow"
                          style={{
                            height: "300px",
                            marginBottom: "90px",
                            width: "100%",
                          }}
                          value={tos}
                          onChange={setTos}
                        />
                      </div>
                      <div className="form-group d-flex justify-content-center">
                        <button className="btn btn-primary">
                          Update TOS {loader}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Tos;
